import { UserSummary } from 'types/api';
import Image from 'next/image';
import { getImageUrl } from 'lib/api';

import styles from './DestinationHeader.module.css';

type Props = {
  details: UserSummary;
};

const DestinationHeader = ({ details: { firstName, cover, photo } }: Props) => {
  const title = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  const newCover = cover ?? `https://app.thatch.co/assets/assets/covers/cover1.webp`;

  return (
    <div className="wf-section">
      <div className="section-container w-container">
        <div className="w-layout-grid hero-grid">
          <div id="w-node-f509cfdd-f158-6b07-47b0-9c4e2fc93abc-7832ad33" className="hero-text-wrapper">
            <h1 id="w-node-_8dc4bff1-d81f-0cc6-602c-63424fddc37f-7832ad33" className="page-title">
              Modern guides curated by modern travelers
              <br />
            </h1>
            <div className="hero-description">
              Shop digital guide+maps curated by {title} travel creators and assemble the perfect plan in just a few
              clicks.
            </div>
          </div>
          <div id="w-node-_8df73f08-aa4b-5334-d104-5f34a1dde326-7832ad33" className="hero-image-wrapper">
            <Image
              src={getImageUrl(newCover)}
              className={`hero-image ${styles.coverImageOverride}`}
              width={767}
              height={767}
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 96vw"
              layout="raw"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationHeader;
