import AuthorTile from 'components/destinations/AuthorTile';
import { UserSummary } from 'types/api';

type Props = {
  authors: UserSummary[];
  destination: string;
};

function AuthorList({ authors, destination }: Props) {
  return (
    <div className="wf-section">
      <div className="section-container w-container">
        <h2 className="section-title white">Hire a travel creator</h2>
        <div className="section-description white">
          Need help planning your trip? Want a personalized guide+map? Hire a travel creator.
        </div>
        <div className="w-layout-grid creator-list">
          {authors.map((author: UserSummary) => (
            <AuthorTile key={`author-${author.uid}`} author={author} destination={destination} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default AuthorList;
