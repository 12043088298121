import { loadAnalytics } from 'lib/analytics';
import { getImageUrl, getOptionalImageUrl } from 'lib/api';
import Image from 'next/image';
import Link from 'next/link';
import type { UserSummary } from 'types/api';
import Avatar from '../../../public/images/avatar.png';

type Props = {
  author: UserSummary;
  destination: string;
};

const appUrl = process.env.NEXT_PUBLIC_APP_URL ?? 'http://localhost:3000';

function track(destination: string, author: UserSummary) {
  console.log('Handling creator clicked event: %o', author);
  loadAnalytics().then(({ analytics, logEvent }) => {
    logEvent(analytics, 'select_item', {
      item_list_id: `creators_${destination}`,
      item_list_name: `${destination} creators`,
      items: [
        {
          item_id: author.uid,
          item_name: author.name,
          item_category: 'Creators',
          item_variant: destination,
        },
      ],
    });
  });
}

const AuthorTile = ({ author, destination }: Props) => {
  const placeholderId = (author.id % 4) + 1;
  const cover = author.cover ?? `https://app.thatch.co/assets/assets/covers/cover${placeholderId}.webp`;

  return (
    <Link href={`${appUrl}/view/profile/${author.uid}`} passHref>
      <a
        className="creator-card-link-wrapper w-inline-block"
        target="_blank"
        onClick={(e) => track(destination, author)}
      >
        <div className="creator-card-wrapper">
          <div className="profile-cover-photo-wrapper">
            <Image src={getImageUrl(cover)} layout="raw" className="profile-cover-photo" width="200" height="143" />
          </div>
          <div className="profile-photo-wrapper">
            <Image
              src={getOptionalImageUrl(author.photo) ?? Avatar.src}
              layout="raw"
              width="106"
              height="106"
              alt=""
              className="profile-photo"
            />
          </div>
          <h3 className="creator-firstlast-name white">{author.name}</h3>
          <div className="creator-username">@{author.username}</div>
        </div>
      </a>
    </Link>
  );
};

export default AuthorTile;
