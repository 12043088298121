import React from 'react';

const Footer = () => {
  return (
    <>
      <div className="section footer wf-section">
        <div className="footer-section-container w-container">
          <img
            src="https://assets.website-files.com/624c8b84a7e91a8bee32ad32/624fa4da1db074124f7e7827_thatch_logo.svg"
            loading="lazy"
            width="69"
            alt=""
            className="footerlogo"
          />
          <div className="footer-links">
            <a href="https://forms.gle/tQgrFiNsHaxXDPUG8" className="nav-link">
              Become a Seller
            </a>
            <a href="https://angel.co/company/thatch-travel/jobs" className="nav-link">
              Careers
            </a>
            <a href="https://thatch.notion.site/Privacy-Policy-408c99bc42c14705a1c8158d74ec5bbe" className="nav-link">
              Privacy
            </a>
            <a href="https://thatch.notion.site/Terms-of-Service-3062a81446f64df0b4a3ddcd04531de6" className="nav-link">
              Terms
            </a>
            <a href="https://thatch.notion.site/Copyright-Policy-30cdb522002b477aa62e4369e768b971" className="nav-link">
              Copyright
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
