import Footer from 'components/common/Footer';
import Header from 'components/common/Header';
import AuthorList from 'components/destinations/AuthorList';
import BoardList from 'components/destinations/BoardList';
import DestinationHeader from 'components/destinations/DestinationHeader';
import StoreFooter from 'components/destinations/StoreFooter';
import { getUrl } from 'lib/api';
import type { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { stringify } from 'querystring';
import React from 'react';
import type { BoardDetails, UserSummary } from 'types/api';

const AnalyticsTracker = dynamic(() => import('components/AnalyticsTracker'));

type Props = {
  destination: string;
  details: UserSummary;
  boards: BoardDetails[];
  authors: UserSummary[];
};

export const getStaticPaths: GetStaticPaths = async (context) => {
  return {
    paths: [{ params: { destination: 'italy' } }],
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps<Props> = async (context) => {
  const { destination } = context.params ?? {};

  if (!destination) {
    return {
      notFound: true,
    };
  }

  const tasks = ['details', 'boards', 'authors']
    .map((path) => getUrl(`/market/${destination}/${path}`))
    .map((url) => {
      console.log('loading url: %s', url);
      return fetch(url)
        .then((it) => {
          console.log('URL loaded [%s]: %s', it.statusText, url);
          return it.json();
        })
        .catch((e) => {
          console.log('Error loading url [%s]: %s', url, e);
          return [];
        });
    });

  const [details, boards, authors] = await Promise.all(tasks);

  return {
    props: {
      destination: destination!.toString(),
      details,
      boards,
      authors,
    },
    revalidate: parseInt(process.env.REVALIDATE_TIMEOUT ?? '10'), // Default to 10 seconds.
  };
};

function Home({ authors, boards, details }: InferGetStaticPropsType<typeof getStaticProps>) {
  return (
    <>
      <Head>
        <title>Thatch Marketplace - {details.firstName}</title>
        <link
          rel="preload"
          as="style"
          href="https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic%7CRoboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic%7CRoboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic"
          media="all"
        />
        <noscript>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic%7CRoboto:100,100italic,300,300italic,regular,italic,500,500italic,700,700italic,900,900italic"
          />
        </noscript>
        <link
          rel="stylesheet"
          href="https://assets.website-files.com/624c8b84a7e91a8bee32ad32/css/marketplace-mvp-f35809.webflow.e6169d0e7.css"
        />
      </Head>
      <Header />
      <AnalyticsTracker contentType="destination" contentId={details.username} />
      <DestinationHeader details={details} />
      <BoardList boards={boards} destination={details.username} />
      <AuthorList authors={authors} destination={details.username} />
      <StoreFooter />
      <Footer />
    </>
  );
}

export default Home;
