import { UserSummary } from 'types/api';

export const apiBaseUrl = process.env.NEXT_PUBLIC_API_ENDPOINT;

export const fetcher = <T>(input: RequestInfo, init?: RequestInit): Promise<T> =>
  fetch(input, init).then((res) => res.json());

export function getUrl(path: string): string {
  return `${apiBaseUrl}/${toRelativePath(path)}`;
}

export function getOptionalImageUrl(path?: string): string | null {
  if (!path) {
    return null;
  }

  return getImageUrl(path);
}

export function getImageUrl(path: string): string {
  if (path.startsWith('http')) {
    return path;
  }

  return `${apiBaseUrl}/images/${toRelativePath(path)}`;
}

export function getProfileImage(profile: UserSummary): string {
  const placeholderId = (profile.id % 4) + 1;
  const cover = profile.cover ?? `https://app.thatch.co/assets/assets/covers/cover${placeholderId}.webp`;
  return getImageUrl(cover);
}

export function getAppUrl(path: string): string {
  return `${process.env.NEXT_PUBLIC_APP_URL ?? 'http://localhost:3200'}/${toRelativePath(path)}`;
}

export function toRelativePath(path: string): string {
  return path.replace(/^\//g, '');
}
