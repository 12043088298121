import BoardTile from 'components/destinations/BoardTile';
import { BoardDetails } from 'types/api';

type Props = {
  boards: BoardDetails[];
  destination: string;
};

function BoardList({ boards, destination }: Props) {
  return (
    <div className="section wf-section">
      <div className="section-container w-container">
        <h2 className="section-title white">Guide+Maps</h2>
        <div className="section-description white">
          Shop curated travel information to save you hours of research and planning. <br />
        </div>
        <div className="w-layout-grid board-list">
          {boards.map((board: BoardDetails) => (
            <BoardTile key={`board-${board.id}`} board={board} destination={destination} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BoardList;
