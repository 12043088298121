import Image from 'next/image';
import React from 'react';

const Header = () => {
  return (
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar w-nav"
    >
      <div className="container-3 w-container">
        <a href="https://www.thatch.co" className="w-nav-brand image">
          <Image
            src="https://assets.website-files.com/624c8b84a7e91a8bee32ad32/624f4c475944363bc749af95_Horizontal-Logotype.svg"
            loading="lazy"
            width="157"
            height="36"
            alt=""
          />
        </a>
        <nav role="navigation" className="nav-menu w-nav-menu">
          <a href="https://www.thatch.co/about" className="nav-link w-nav-link">
            About
          </a>
          <a href="https://www.thatch.co/sellers" className="nav-link w-nav-link">
            Sellers
          </a>
          <a href="https://app.thatch.co/welcome/register" className="secondary-button nav w-button">
            Log in
          </a>
          <link rel="prefetch" href="https://app.thatch.co/welcome/register" />
        </nav>
        <div className="menu-button w-nav-button">
          <div className="menu-mobile">
            <div
              data-w-id="c5f9badf-419c-fc4b-480c-105cd8c54ec4"
              data-animation-type="lottie"
              data-src="https://assets.website-files.com/6207e4d403038b0ad7cf6cb9/620d7f746ef11214421c5d31_34311-hamburger-icon.json"
              data-loop="0"
              data-direction="1"
              data-autoplay="1"
              data-is-ix2-target="0"
              data-renderer="svg"
              data-default-duration="2.8"
              data-duration="0"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
