import { gTagTracking, loadAnalytics } from 'lib/analytics';
import { getAppUrl, getImageUrl } from 'lib/api';
import type { NextPage } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import type { BoardDetails } from 'types/api';

import styles from './BoardTile.module.css';

type Props = {
  board: BoardDetails;
  destination: string;
};

const intl = new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' });

function track(destination: string, board: BoardDetails) {
  console.log('Handling board clicked event: %o', board);
  loadAnalytics().then(({ analytics, logEvent }) => {
    logEvent(analytics, 'select_item', {
      item_list_id: `boards_${destination}`,
      item_list_name: `${destination} boards`,
      items: [
        {
          item_id: board.token,
          item_name: board.name,
          item_category: 'Boards',
          item_variant: destination,
          affiliation: board.author.username,
          price: board.price,
        },
      ],
    });
  });

  console.log('Sending gtag');
  gTagTracking();
}

const BoardTile: NextPage<Props> = ({ board, destination }) => {
  const placeholderId = (board.id % 4) + 1;
  const cover = board.image ?? `https://app.thatch.co/assets/assets/covers/cover${placeholderId}.webp`;

  return (
    <Link href={getAppUrl(`/view/boards/${board.token}`)} passHref>
      <a className="board-card-link-wrapper w-inline-block" target="_blank" onClick={(e) => track(destination, board)}>
        <div className={`board-card-image-wrapper ${styles.cardImageWrapperOverride}`}>
          <Image
            src={getImageUrl(cover)}
            className={`board-card-image-wrapper ${styles.cardImageOverride}`}
            width={767}
            height={767}
            sizes="(max-width: 767px) 91vw, 478px"
            layout="raw"
            alt=""
          />
        </div>
        <div className="card-details-wrapper">
          <div className="author-line">
            <div className="author-name">Curated by {board.author.name}</div>
          </div>
          <div className="board-title">{board.name}</div>
          <div className="price-tag">
            <div className="text-block">{board.price ?? 0 > 0 ? intl.format(board.price!) : 'Free'}</div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default BoardTile;
