import React from 'react';

const StoreFooter = () => {
  return (
    <>
      <div className="section how-to wf-section">
        <div className="section-container dark w-container">
          <h2 className="section-title white centered large">
            Feel prepared for your trip
            <br />
            without hours of research.
          </h2>
          <div className="_24px-vspacer"></div>
          <div className="section-description white centered">
            Thatch brings together some of the best travel creators from across the internet to <br />
            make it incredibly easy for you to assemble a great trip in a few easy steps:
            <br />
          </div>
          <div className="w-layout-grid how-to-grid">
            <div id="w-node-e1b563fc-e0f8-25a9-ad3a-1c951a39208b-7832ad33" className="how-to-wrapper">
              <div className="stepnumber">
                <div className="text-block-5">01.</div>
              </div>
              <div className="howtotext">Shop travel creators and their curated guide + maps.</div>
            </div>
            <div id="w-node-bc05f5b4-4dea-b4ef-d62f-73b55fed6682-7832ad33" className="how-to-wrapper">
              <div className="stepnumber">
                <div className="text-block-5">02.</div>
              </div>
              <div className="howtotext">Purchase the guide+maps that suit you and your trip.</div>
            </div>
            <div id="w-node-_4f8d072f-7519-3239-3f47-694284e1ab88-7832ad33" className="how-to-wrapper">
              <div className="stepnumber">
                <div className="text-block-5">03.</div>
              </div>
              <div className="howtotext">Voila! All the info is ready to go in your Thatch travel app account.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="section explainer wf-section">
        <div className="section-container dark w-container">
          <h2 className="section-title white centered large">Discover a better way to get away</h2>
          <div className="_24px-vspacer"></div>
          <div className="section-description white centered">
            Thatch is designed from the ground up to be a better experience for <br />
            both travelers and travel creators every step of the way.
            <br />
          </div>
          <div className="w-layout-grid explainer-grid">
            <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d60386f4-7832ad33" className="explainer-wrapper">
              <img
                src="https://assets.website-files.com/624c8b84a7e91a8bee32ad32/625760851ce0f258eb12a9d0_bookmarks_white_24dp.svg"
                loading="lazy"
                alt=""
                className="explainer-image"
              />
              <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d60386f6-7832ad33" className="benefit-text">
                Save &amp; organize all your <br />
                travel info in one place
              </div>
            </div>
            <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d60386fa-7832ad33" className="explainer-wrapper">
              <img
                src="https://assets.website-files.com/624c8b84a7e91a8bee32ad32/6257621154773852298d2aca_blender_white_24dp.svg"
                loading="lazy"
                alt=""
                className="explainer-image"
              />
              <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d60386fc-7832ad33" className="benefit-text">
                Remix info from guides <br />
                into your own custom plans
              </div>
            </div>
            <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d6038700-7832ad33" className="explainer-wrapper">
              <img
                src="https://assets.website-files.com/624c8b84a7e91a8bee32ad32/625761603f10cb222c0cd123_app_shortcut_white_24dp.svg"
                loading="lazy"
                alt=""
                className="explainer-image"
              />
              <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d6038702-7832ad33" className="benefit-text">
                Relax with a modern app. <br />
                No PDFs. No bulky guide books.
              </div>
            </div>
            <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d6038706-7832ad33" className="explainer-wrapper">
              <img
                src="https://assets.website-files.com/624c8b84a7e91a8bee32ad32/6257642aca5d8022dcf09043_movie_filter_white_24dp%20(1).svg"
                loading="lazy"
                alt=""
                className="explainer-image"
              />
              <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d6038708-7832ad33" className="benefit-text">
                Always stay up to date with live, <br />
                multi-media content <br />
              </div>
            </div>
            <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d603870d-7832ad33" className="explainer-wrapper">
              <img
                src="https://assets.website-files.com/624c8b84a7e91a8bee32ad32/6257639fca5d80eee8f08ad7_map_white_24dp.svg"
                loading="lazy"
                alt=""
                className="explainer-image"
              />
              <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d603870f-7832ad33" className="benefit-text">
                Easily find your spots nearby with <br />
                an interactive map <br />
              </div>
            </div>
            <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d6038714-7832ad33" className="explainer-wrapper">
              <img
                src="https://assets.website-files.com/624c8b84a7e91a8bee32ad32/62576697e912e817662ecf19_color_lens_white_24dp.svg"
                loading="lazy"
                alt=""
                className="explainer-image"
              />
              <div id="w-node-_115b147e-11f8-f10e-e8bb-b2b3d6038716-7832ad33" className="benefit-text">
                Work directly with creators <br />
                to plan all or part of your trip
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreFooter;
